import { signal, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { signalStoreFeature, StateSignal, withComputed } from '@ngrx/signals';
import { EmptyFeatureResult, SignalStoreFeature, SignalStoreFeatureResult, SignalStoreSlices } from '@ngrx/signals/src/signal-store-models';
import { Prettify } from '@ngrx/signals/src/ts-helpers';
import { Observable, Subject } from 'rxjs';

export function withEventEmitter<TName extends string, Input extends SignalStoreFeatureResult, TEvent = any>(
  name: TName,
  eventsFactory?: (
    store: Prettify<SignalStoreSlices<Input['state']> & Input['signals'] & Input['methods'] & StateSignal<Prettify<Input['state']>>>,
  ) => Observable<TEvent>,
): SignalStoreFeature<
  Input,
  EmptyFeatureResult & {
    signals: {
      [K in TName]: Signal<Subject<TEvent>>;
    };
  }
> {
  return signalStoreFeature(
    withComputed((store) => {
      const subject = new Subject<TEvent>();
      if (eventsFactory) {
        eventsFactory(store as any)
          .pipe(takeUntilDestroyed())
          .subscribe(subject);
      }
      return {
        [name]: signal(subject),
      } as any as { [K in TName]: Signal<Subject<TEvent>> };
    }),
  );
}
